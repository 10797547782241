<template>
    <div class="col-xl-8 col-lg-7">
        <div class="card shadow mb-4">
            <!-- Card Header - Dropdown -->
            <div
                class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
            >
                <h6 class="m-0 font-weight-bold text-primary">
                    Best-selling Products
                </h6>
                <div class="d-flex align-items-center">
                    <div class="mr-2">Date Interval</div>
                    <div>
                        <select
                            class="form-control"
                            v-model="selectedRange"
                            @change="changeRange"
                        >
                            <option
                                v-for="option in rangeOptions"
                                :key="option.value"
                                :value="option.value"
                            >
                                {{ option.label }}
                            </option>
                        </select>
                    </div>

                    <div class="dropdown no-arrow ml-3">
                        <a
                            class="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i
                                class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"
                            ></i>
                        </a>
                        <div
                            class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                            aria-labelledby="dropdownMenuLink"
                        >
                            <div class="dropdown-header">Actions:</div>
                            <a
                                class="dropdown-item"
                                role="button"
                                @click="exportToExcel"
                                >Download in Excel
                                <i class="fas fa-solid fa-file-excel"></i
                            ></a>
                            <!-- <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#"
                                >Something else here</a
                            > -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Card Body -->
            <div class="card-body">
                <div class="chart-area">
                    <common-table
                        :entities="entities"
                        :headers="headers"
                        :no_action="no_action"
                    ></common-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as XLSX from "xlsx";

export default {
    props: ["headers", "lvEntities", "entityName"],
    data() {
        return {
            entities: this.lvEntities,

            selectedRange: "12",
            // extra: this.dataExtra ?? null,
            // show_table: true,
            no_action: true,
            rangeOptions: [
                { label: "Last Year", value: "12" },
                { label: "Last 6 Months", value: "6" },
                { label: "Last 3 Months", value: "3" },
                { label: "Last Month", value: "1" },
                { label: "All Period", value: "" },
            ],
            // loader: false,
        };
    },
    methods: {
        async changeRange(param) {
            const searchUrl = route(this.entityName);

            let date_interval = param.target.value;

            this.searchData({ interval: date_interval }, searchUrl)
                .then((result) => {
                    this.entities = result.data;
                })
                .catch((error) => {
                    console.log(error);
                    // this.catchResponse(error);
                });
        },
        exportToExcel() {
            // Create a new workbook and a sheet from the JSON data
            let entities_for_export = this.entities.map((item) => {
                const { id, ...rest } = item;
                return rest;
            });

            const worksheet = XLSX.utils.json_to_sheet(entities_for_export);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            // Generate a binary string representation of the workbook
            const wbout = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "binary",
            });

            // Convert the binary string to a Blob
            function s2ab(s) {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i < s.length; i++)
                    view[i] = s.charCodeAt(i) & 0xff;
                return buf;
            }

            const blob = new Blob([s2ab(wbout)], {
                type: "application/octet-stream",
            });

            let option = this.rangeOptions.find(
                (option) => option.value === this.selectedRange
            );

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            let currentMonth = currentDate.getMonth() + 1;
            currentMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;

            // Create a link element and trigger a download
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = currentMonth + "_" + currentYear + "_" + option.label.replace(' ', '_').toLowerCase() + "_best_sellers.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-md-7">
            <div class="row">
                <div class="col-lg-12">
                    <h4>
                        Order #{{ order.id }}
                        <span v-show="extra.is_admin || extra.is_agent"
                            >by <i class="text-info">{{ order.user.name }}</i>
                        </span>
                    </h4>
                </div>
                <div class="col-md-auto d-inline">
                    Order Status:
                    <span
                        class="text-info medium text-uppercase d-inline"
                        v-if="extra.is_admin && !status_disable"
                    >
                        <span class="float-right ml-1">
                            <select
                                v-model.lazy="order.status.value"
                                class="form-control"
                                :disabled="status_disable"
                                @change="changeStatus"
                            >
                                <option
                                    v-for="(key, index) in extra.statuses"
                                    :key="key"
                                    :value="index"
                                >
                                    {{ key }}
                                </option>
                            </select>
                        </span>
                    </span>
                    <span
                        class="text-info medium text-uppercase d-inline"
                        v-else
                    >
                        {{ order.status.key || "" }}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col offset-md-6">
                    Order Date:
                    <span class="text-info medium text-uppercase">
                        {{ order.date_order | formatDate }}
                    </span>
                </div>
            </div>
            <div v-if="has_agent" class="row">
                <div class="col offset-md-6">
                    Created by:
                    <span class="text-info medium text-uppercase">
                        {{ order?.agent?.name }}
                    </span>
                </div>
            </div>
            <div class="row">
                <div
                    v-if="extra.is_admin && order.status.key != 'Failed'"
                    class="col offset-md-6 pt-2 d-print-none"
                >
                    Order Print:
                    <button
                        class="btn btn-primary rounded btn-sm"
                        @click="printWindow()"
                    >
                        <i class="fas fa-print"></i>
                    </button>
                </div>
            </div>
            <div class="row">
                <div
                    v-if="order.invoice_token"
                    class="col offset-md-6 pt-2 d-print-none"
                >
                    <a :href="linkInvoice" target="_blank" ref="nofollow">
                        Download Invoice:
                    </a>
                    <span class="text-info medium text-uppercase">
                        <a :href="linkInvoice" target="_blank" ref="nofollow">
                            {{ order.invoice_token }}
                        </a>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-12 py-2">
            <div class="row" v-if="order.tracking_url">
                <div class="col-lg-1 pr-0">Tracking Url:</div>
                <div class="col-lg-11 text-left pl-0">
                    <a :href="order.tracking_url" target="blank" ref="nofollow">
                        {{ order.tracking_url }}
                    </a>
                </div>
            </div>
        </div>
        <div class="col-md-12 py-2">
            <div class="row" v-if="order.messages">
                <div class="col-lg-2 pr-0">Messages from AlbaOptics:</div>
            </div>
            <div class="row">
                <div class="col offset-md-2 col-lg-9 text-left pl-0">
                    <div
                        class="row"
                        v-for="(key, value) in order.messages"
                        :key="key"
                        :value="value"
                    >
                        <span class="mr-1"
                            ><b>(on {{ value }})</b>
                        </span>
                        {{ key }}
                    </div>
                </div>
            </div>
        </div>
        <modal
            ref="modalStatus"
            v-show="isModalStatusVisible"
            @close="closeModal"
        >
            <template v-slot:header>
                <h4 class="modal-title" v-if="order.status.key == 'Shipped'">
                    Order ready for Shipping
                </h4>
                <h4 class="modal-title" v-else-if="order.status.key == 'Failed'">
                    Reason of Failed Order
                </h4>
                <h4 class="modal-title" v-else>Confirm Order Pending</h4>
            </template>
            <template v-slot:body>
                <ValidationObserver ref="statusModal">
                    <form id="shipping-form" class="main-form" role="form">
                        <div class="form-group">
                            <label v-if="order.status.key !== 'Failed'" for="note">Add note to order:</label>
                            <ValidationProvider
                                name="Note"
                                rules=""
                                v-slot="{ classes, errors }"
                            >
                                <textarea
                                    class="form-control"
                                    rows="3"
                                    v-model="message"
                                    rules=""
                                    :class="{
                                        'border-danger': classes.failed,
                                    }"
                                ></textarea>
                                <i
                                    v-show="errors[0]"
                                    class="fas fa-exclamation-circle text-danger"
                                ></i>
                                <span class="help text-danger">{{
                                    errors[0]
                                }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group" v-if="order.shipping_cost && order.status.key !== 'Failed'">
                            <label>Tracking Url</label>
                            <ValidationProvider
                                name="tracking url"
                                rules="required|url"
                                v-slot="{ classes, errors }"
                            >
                                <!-- v-model="fresh_product.image" -->
                                <input
                                    v-model="order.tracking_url"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'border-danger': classes.failed,
                                    }"
                                    placeholder="tracking url"
                                />
                                <i
                                    v-show="errors[0]"
                                    class="fas fa-exclamation-circle text-danger"
                                ></i>
                                <span class="help text-danger">{{
                                    errors[0]
                                }}</span>
                            </ValidationProvider>
                        </div>
                    </form>
                </ValidationObserver>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click="closeModal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="clickModal"
                >
                    {{ text_modal }} Order
                </button>
            </template>
        </modal>
    </div>
</template>
<script>
export default {
    props: ["order", "extra"],
    data() {
        return {
            status_disable: true,
            status: {},
            isModalStatusVisible: false,
            message: null,
            linkInvoice: route("order.invoice", [this.order.id]),
        };
    },
    mounted() {
        this.status_disable = !["Pending", "Confirmed"].includes(
            this.order.status.key
        );
    },
    watch: {
        order(val) {
            this.status_disable = !["Pending", "Confirmed"].includes(
                val.status.key
            );
        },
    },
    computed: {
        text_modal() {
            let text_m = '';

            switch (this.order.status.key) {
                case "Confirmed":
                    text_m = 'Save';
                    break;

                case "Failed":
                    text_m = 'Delete';
                    break;
            
                default:
                    text_m = 'Ship';
                    break;
            }
            return text_m;
            // return this.order.status.key == "Confirmed" ? "Save" : "Ship";
        },
        has_agent() {
            return !Object.values(this.order.agent).every((value) => !value);
        },
    },
    methods: {
        reset() {
            this.closeModal();
            this.order.tracking_url = null;
            this.message = null;
        },
        closeModal() {
            process.nextTick(() => {
                this.isModalStatusVisible = false;
                let order_status_key = this.$parent.$parent.entity.status.key
                let status_ix = Object.values(this.extra.statuses).findIndex(
                    function (value) {
                        return value == order_status_key;
                    }
                );
                
                this.message = null;
                this.order.tracking_url = null;
                status_ix = status_ix == 0 ? 0 : status_ix;
                let prev_status = Object.entries(this.extra.statuses)[
                    status_ix
                ];
                this.order.status.value = prev_status[0];
                this.order.status.key = prev_status[1];
            });
        },
        async clickModal() {
            
            if (this.order.status.key == "Confirmed") {
                this.order["message"] = this.message;
                await this.$parent.$parent.$refs.btn_save_order.click();
            } else if (this.order.status.key == "Shipped" || this.order.status.key == "Failed") {
                this.order["message"] = this.message;
                await this.updateOrder();
            }
            // if(this.order.status.key == "Failed") {
            //     this.order["message"] = this.message;                
            // }
        },
        async updateOrder() {
            const valid = await this.$refs.statusModal.validate();
            if (valid) {
                if(this.order.status.key == 'Shipped')
                this.$parent.$emit("shipOrder");
                else if(this.order.status.key == 'Failed'){                                     
                    this.$parent.$emit("failedOrder", this.$parent.$options.name);
                }
            }
        },
        changeStatus($event) {            
            this.order.status.value = parseInt($event.target.value);
            this.order.status.key = this.extra.statuses[$event.target.value];

            if (!this.status_disable ) {
                this.isModalStatusVisible = true;
            }
            
            this.$emit("statusEnabled");
        },
        printWindow() {
            window.open(route("order.print", [this.order.id]));
        },
    },
};
</script>

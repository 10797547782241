var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card shadow mb-4 col-md-6"},[_c('a',{ref:"accordionPanel",staticClass:"d-block card-header py-3",class:{ disabled: _vm.addDisabled },attrs:{"data-toggle":"collapse","role":"button","aria-expanded":this.accordion.aria,"aria-controls":"new-user"},on:{"click":function($event){$event.preventDefault();return _vm.enableAdd()}}},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[(!_vm.fresh_user.id)?_c('span',[_vm._v("Add New")]):_c('span',[_vm._v("Modify")]),_vm._v(" User\n    ")])]),_vm._v(" "),_c('div',{staticClass:"collapse",class:this.accordion.collapse,attrs:{"id":"new-user"}},[_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"formObs",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"main-form",attrs:{"id":"user-form","role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.processForm)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Name")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_user.name),expression:"fresh_user.name"},{name:"lowercase",rawName:"v-lowercase"}],staticClass:"form-control",class:{
                    'border-danger': classes.failed
                  },attrs:{"type":"text"},domProps:{"value":(_vm.fresh_user.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_user, "name", $event.target.value)}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),(_vm.is_admin)?_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Price List")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"pricelist","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pricelist_id),expression:"pricelist_id"}],staticClass:"form-control",class:{
                    'border-danger': classes.failed
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.pricelist_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"selected":"","disabled":""}},[_vm._v("\n                    Please select one\n                  ")]),_vm._v(" "),_vm._l((_vm.pricelists),function(p){return _c('option',{key:p.id,domProps:{"value":p.id}},[_vm._v("\n                    "+_vm._s(p.name)+"\n                  ")])})],2),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Email")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_user.email),expression:"fresh_user.email"},{name:"lowercase",rawName:"v-lowercase"}],staticClass:"form-control",class:{
                    'border-danger': classes.failed
                  },attrs:{"disabled":_vm.fresh_user.id,"type":"email"},domProps:{"value":(_vm.fresh_user.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_user, "email", $event.target.value)}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),(_vm.is_admin)?_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"shipping"}},[_vm._v("Has Shipping")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.shipping),expression:"shipping"}],attrs:{"id":"shipping","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.shipping)?_vm._i(_vm.shipping,null)>-1:(_vm.shipping)},on:{"change":function($event){var $$a=_vm.shipping,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.shipping=$$a.concat([$$v]))}else{$$i>-1&&(_vm.shipping=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.shipping=$$c}}}})]):_vm._e()]),_vm._v(" "),_c('profile-form',{ref:"profileForm",attrs:{"entity":_vm.fresh_user,"is_admin":_vm.is_admin,"extra":_vm.extra}}),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_user.id),expression:"fresh_user.id"}],attrs:{"hidden":""},domProps:{"value":(_vm.fresh_user.id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_user, "id", $event.target.value)}}}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary"},[_vm._v("Save")]),_vm._v(" "),_c('div',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("\n              Cancel\n            ")])])],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }